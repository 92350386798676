/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import CampHoc from '../../components/CampHoc';

// eslint-disable-next-line react/prop-types
const RuskaSuomiRuotsiNorja = ({ data }) => {
  const seo = {
    url: 'https://www.discoverlapland.fi/maastopyoraily/maastopyoravaellus-suomi-ruotsi-norja',
    image: `https://www.discoverlapland.fi${data.imageSEO.childImageSharp.gatsbyImageData.images.fallback.src}`,
    title: '¬Maastopyörävaellus Suomi - Ruotsi - Norja ',
    description:
      'Tämän vuoden MTB-retkemme suuntautuu Suomi-neidon käsivarteen jännittävälle maastopyörävaellukselle. Kaksipäiväinen maastopyörävaellus alkaa Kilpisjärveltä, josta ajamme Ruotsiin ja retki päättyy Norjaan. Syksyn noin 65 km ruskareitti kulkee uskomattoman hienoissa vuoristomaisemissa, maastopyöräilyä parhaimmillaan!',
  };

  return (
    <CampHoc
      heroImage={data.campBg.childImageSharp.gatsbyImageData}
      seo={seo}
      camp="ruska-maastopyoravaellus-suomi-ruotsi-norja"
    >
      <div className="camp-text-content">
        <h2>Maastopyörävaellus Suomi - Ruotsi - Norja</h2>
        <h3>31.8.-3.9.2023</h3>
        <p className="bolded">
          Tämän vuoden MTB-retkemme suuntautuu Suomi-neidon käsivarteen
          jännittävälle maastopyörävaellukselle. Kaksipäiväinen
          maastopyörävaellus alkaa Kilpisjärveltä, josta ajamme Ruotsiin ja
          retki päättyy Norjaan. Syksyn noin 65 km ruskareitti kulkee
          uskomattoman hienoissa vuoristomaisemissa, maastopyöräilyä
          parhaimmillaan!
        </p>
        <br />
        <p>
          Tällä retkellä ei suoriteta, vaan ennen kaikkea nautitaan
          matkanteosta. Mukaan paljon evästä ja napostetltavaa, kunnolla juomaa
          ja rentoutunut mieli. Matkalla yövytään Ruotsin puolella
          erämaakämpässä Pältsa Stugassa, jossa tunnelma on käsin kosketeltavan
          hieno. Pältsan vuorimökkiä käytettiin toisen maailmansodan aikana
          vastarintaliikkeen tukikohtana.
        </p>
      </div>
      <br />
      <div className="camp-text-content">
        <p>
          Retki sopii sinulle, jolla on jo maastopyöräilykokemusta tai olet
          ollut mukanamme polkemassa Ylläs-Levi -retkellämme. Maastossa tulee
          olemaan nousua ja laskua, helpompaa polkua sekä teknisempää maastoa.
        </p>
        <br />
        <p>
          Oppaina retkellä toimivat meidän tutut MTB-oppaamme Laura Ollila ja
          Juuso Laitinen. Tämä dynaaminen duo pitää sinusta huolta koko retken
          ajan ottaen kaikki osallistujat huomioon ja tarjoten juuri sinulle
          oikeaa vauhtia hienoilla poluilla.
        </p>
        <br />
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <GatsbyImage
            image={data.laura.childImageSharp.gatsbyImageData}
            className="offering-img"
            alt="image"
          />
          <GatsbyImage
            image={data.juuso.childImageSharp.gatsbyImageData}
            className="offering-img"
            alt="image"
          />
        </div>
        <p className="bolded">Maastopyörävaelluksen aikataulu:</p>
        <p>
          31.8.2023 tapaaminen Lapland Hotels Kilpiksellä, jossa vietämme yön.
          Käymme illalla retkeä sekä tarvikkeita läpi ja pakkaamme kaiken
          valmiiksi aamulle. Illalla nautimme yhteisen dinnerin ja skoolaamme
          tulevalle seikkailulle.
        </p>
        <br />
        <p>
          Aamulla 1.9. kuljetus MTB-vaelluksen lähtöpaikkaan, josta retki alkaa.
          Ensimmäisenä päivänä tulee ajoa noin 25 km tunturiylängön hienoissa
          maisemissa. Ajettava polku vaihtelee soratiestä, mönkijäpolkuun,
          suo-osuuksista koivikkoon, komeiden pahtojen ympäröimänä. Edessä
          siintää Ruotsin puolen Pältsan massiivit sekä Norjan vuoret. Ajopäivä
          päättyy nousun jälkeen tunnelmalliseen Pältsa Stuganilla, jossa
          yövymme. Tupavahti toivottaa meidän tervetulleiksi ja ohjaa
          maakuusoppeihin. Illalla valmistetaan omat maukkaat iltaruuat,
          saunotaan sekä fiilistellään päivää.
        </p>
        <br />
        <GatsbyImage
          image={data.campImg3.childImageSharp.gatsbyImageData}
          className="offering-img"
          alt="image"
        />
        <p>
          Aamulla 2.9. hyvin nukutun yön jälkeen alkaa Pältsan tuvalta
          ensiluokkainen maastopyöräily kohti Norjaa. Tässä on ajonautintoa
          parhaimmillaan! Letka ajaa ylängöllä kiemurtelevalla
          moreemiharjanteella ja edessä avautuvat Norjat vuoret ja Paras,
          vieressä näkyy Pältsan massiivi. Ennen Norjan rajaa moreeniharjanne
          loppuu ja polku muuttuu kiviseksi. Päivän aikana ajoa tulee noin 40
          km, joista viimeiset 10 km on upeaa alamäkeä. Kun kaikki saapuvat
          päätepisteeseen Norjan puolelle, rytmiryhmä kuljetetaan kalustoineen
          takaisin Kilpisjärven hotellille. Illalla pestään pyörät, saunotaan,
          syödään hyvin ja skoolataan upealle seikkailulle! 3.9. alkaa kaikkien
          kotimatka.
        </p>
        <br />
        <p className="bolded">Retken hinta on 680€ sis alv.</p>
        <br />
        <p className="bolded">Retken hintaan kuuluu:</p>
        <br />
        <p>
          - Kaksi yötä Lapland Hotels Kilpiksessä jaetussa retkeilyhuoneessa,
          sisältäen aamiaisen ja rantasaunan <br />
          - Yksi yö Pältsa Stugassa <br />
          - Kaksi opasta, reittisuunnitelma, pakkauslista ja
          turvallisuussuunnitelma <br />
          - Kuljetus Kilpisjärveltä MTB-vaelluksen lähtöpaikkaan ja
          paluukuljetus Norjasta takaisin Kilpisjärven hotellille <br />
        </p>
        <br />
        <p>
          Jos lähdet matkaan sähköpyörällä, tulee sinulla olla vara-akku mukana,
          sillä akkuja ei saa lataukseen matkalla.
        </p>
        <br />
        <p className="bolded">Tarvitset mukaan:</p>
        <p>
          Maastopyörä + varakumi, pieni pumppu <br />
          Ajovaatteet <br />
          Kypärä <br />
          Reppu, johon mahtuu (tai pyörän rungossa olevissa kuljetuspusseissa):{' '}
          <br />
          - Juomapullo / juomarakko <br />
          - Kahden päivän eväät retkelle <br />
          - Makuuspussilakana / pieni makuupussi (kaikki tavara kuljetetaan
          omassa repussa) <br />
          - Vaatteet Pältsa Stugalle (yöpymäpaikassa saunamahdollisuus) <br />
        </p>
      </div>
      <br />
      <div className="camp-text-content">
        <span className="bolded">
          Ilmoittaudu näin mukaan osoitteeseen{' '}
          <a href="mailto: anettelatvapiikkila@gmail.com">
            anettelatvapiikkila@gmail.com
          </a>
        </span>
        <p>
          Otsikko: Maastopyörävaellus Suomi - Ruotsi - Norja 31.8.-3.9.2023{' '}
          <br />
          Nimi: <br />
          Ikä: <br />
          Paikkakunta: <br />
          Puhelinnumero: <br />
          Aikaisempi kokemuksesi maastopyöräilystä: <br />
          Oletko ollut meidän Ylläs-Levi retkillä mukana? <br />
          Mikä on terveydentilasi (onko polvi/nivelongelmia, astmaa jne.)?
        </p>
        <br />
        <p>
          Mukaan otetaan maksimissaan 10 naista, minimimäärä retken toteutukseen
          on 6 naista.
        </p>
        <br />
        <p>
          Sinulle tulee kustannettavaksi omat kuljetukset Kilpisjärvelle ja
          takaisin kotiin sekä omat ruokailut to-su (perjantaina ja sunnuntaina
          hintaan kuuluvat hotellin aamiaiset + sauna).
        </p>
      </div>
      <br />
      <div className="camp-text-content">
        <span className="bolded">Retken varaus- ja peruutusehdot:</span>
        <p>
          Kun olet ilmoittautunut mukaan sähköpostitse, saat paluupostina
          materiaalikululaskun 140€. Kun lasku on maksettu, on paikka retkelle
          varattu ja saat paluupostina retken materiaalit.
        </p>
        <br />
        <p>
          MTB-vaellusken loppulaskun 540€ saat sähköpostiisi retken
          aloituspäivänä, jolloin lasku tulee maksaa ja lähettää tästä
          kuittikopio osoitteeseen{' '}
          <a href="mailto: anettelatvapiikkila@gmail.com">
            anettelatvapiikkila@gmail.com
          </a>
          .
        </p>
        <br />
        <p>
          Jos perut osallistumisesi retkelle missä kohtaa tahansa, pidätämme
          toimitetut materiaalien kulut sekä toimistokulut 140€.
          Ilmoittautumisen peruutus tulee tehdä sähköpostitse osoitteeseen {' '}
          <a href="mailto: anettelatvapiikkila@gmail.com">
            anettelatvapiikkila@gmail.com
          </a>
          . Lue lisää varaus - ja peruutusehdoistamme{' '}
          <Link className="bolded" to="/varaus-ja-peruutusehdot">
            tästä
          </Link>
          .
        </p>
        <br />
        <p>
          Pidäthän huolen, että sinulla on voimassa oleva vapaa-ajan- tai
          matkavakuutus retken aikana.
        </p>
        <br />
        <p>
          Tekisikö mieli mukaan tälle upealle MTB-vaellukselle? Mikäli sinulla
          on kysyttävää, ole yhteydessä Anetteen joko meilitse
          <a href="mailto: anettelatvapiikkila@gmail.com">
            anettelatvapiikkila@gmail.com
          </a>{' '}
          tai soittamalla 040 772 4124.
        </p>
      </div>
    </CampHoc>
  );
};

export const query = graphql`
  {
    campBg: file(
      relativePath: { eq: "images/mtb/ruska/anette_hetta_remote-46.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    campImg1: file(relativePath: { eq: "images/mtb/ruska/paltsa-1.jpeg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    campImg2: file(relativePath: { eq: "images/mtb/ruska/paltsa-2.jpeg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    campImg3: file(relativePath: { eq: "images/mtb/ruska/paltsa-3.jpeg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    juuso: file(relativePath: { eq: "images/mtb/ruska/juuso-1.JPG" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    laura: file(relativePath: { eq: "images/mtb/ruska/laura-1.JPG" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    imageSEO: file(
      relativePath: { eq: "images/mtb/ruska/anette_hetta_remote-46.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1366, layout: FIXED)
      }
    }
  }
`;

export default RuskaSuomiRuotsiNorja;
